.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  grid-auto-rows: minmax(10px, auto);
}
.startTime {
  grid-column: 1 / 3;
  grid-row: 1;
}
.card {
  padding: 0px !important;
}
