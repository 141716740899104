@import "@blueprintjs/core/lib/scss/variables";

.main {
  width: 100%;
  height: 100%;
}

.textArea {
  display: block;
  margin: 5px;
  width: 50%;
  padding: 0;
  max-width: 400px;
  min-width: 200px;
  min-height: 200px;
}

.textArea:focus-visible {
  border-width: 0.5px;
  outline-color: grey;
  outline-width: 0.5px !important;
}
.sendButton {
  margin: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
}
h3 {
  color: $pt-link-color;
}
