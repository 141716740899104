.wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding: 5px;
  align-items: center;
}
.backgroundColour {
  background-color: aliceblue;
}
.backgroundRed {
  background-color: red;
  color: white;
}

.startTime {
  padding: 10px;
}

.length {
  text-align: center;
  padding: 10px;
  background-color: yellow;
  font-weight: bold;
  font-size: larger;
  color: black;
}

.remaining {
  text-align: center;
  font-weight: bold;
  font-size: larger;
}

.removeButton {
  font-weight: bold;
  font-size: larger;
}
