@import "@blueprintjs/core/lib/scss/variables";

:root {
  --font: "Roboto";
  --headerFontColor: $pt-link-color;
}

.header {
  background-color: #2d72d2;
  width: 100%;
  padding: 1.5%;
  padding-bottom: 5px;
}
.container {
  width: 98%;
  display: inline-block;
  justify-content: space-between;
}
.headerText {
  font-size: larger;
  color: white;
  float: left;
  font-family: var(--font);
  font-weight: 300;
}
a {
  margin-left: 10px;
  margin-right: 10px;
  font-family: var(--font);
  color: #fbd065 !important;
}

.signInButton {
  float: right;
  font-family: var(--font);
  padding: 1px 5px 1px 5px !important;
  display: inline-flex !important;
}
