@import "@blueprintjs/core/lib/scss/variables";

.paragraph {
  margin: 15px !important;
  font-family: var(--font);
}

.heading {
  margin: 10px !important;
  font-family: var(--font);
}
h2 {
  color: $pt-link-color;
}

strong {
  font-size: large;
}
